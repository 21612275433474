/**
 * @file 入口文件
 * @author hardylin <hardylin@tencent.com>
 */
import SadConfigProdiver from './components/config-provider'
import SadHeader from './components/header'
import SadNav from './components/nav'
import SadProduct from './components/product'

/**
 * Vue 插件安装方法
 * @param {Object} Vue - Vue 类
 */
function install(Vue) {
  // #region 自定义组件
  Vue.component(SadConfigProdiver.name, SadConfigProdiver)
  Vue.component(SadHeader.name, SadHeader)
  Vue.component(SadNav.name, SadNav)
  Vue.component(SadProduct.name, SadProduct)
  // #endregion

  // #region 实例方法
  // Vue.prototype.$actionSheet = ActionSheet
  // Vue.prototype.$popup = Popup
  // Vue.prototype.$swiperImage = SwiperImage
  // Vue.prototype.$toast = Toast
  // #endregion
}

// #region 自动安装
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}
// #endregion

export default {
  install,
}
export { SadConfigProdiver, SadHeader, SadNav, SadProduct }
