/**
 * @file 接口 - 产品
 * @author hardylin <hardylin@tencent.com>
 */
import axios from 'axios'
import { URL } from '../constants/index'

export default {
  /**
   * 请求导航数据
   */
  getNav() {
    return axios.get(`${URL.CDN}/v0/nav.json`).then(result => {
      return result.data
    })
  },
}
