<script lang="jsx">
import { URL } from '../../../../../src/scripts/constants/index'
import axios from '../../../../../src/scripts/request/axios'

export default {
  name: 'sad-config-provider',

  props: {
    development: {
      type: Boolean,
      default: false
    }
  },

  provide() {
    return {
      development: this.development
    }
  },

  render() {

    // #region 修改全局配置
    const { development } = this
    axios.defaults.baseURL = `https://${development ? 'tmoss' : 'moss'}${ URL.ENV }com`
    // #endregion

    return this.$slots.default
  }
}
</script>