/**
 * @file Axios 自定义实例
 * @author hardylin <hardylin@tencent.com>
 */
import axios from 'axios'
import { URL } from '../constants/index'

export default axios.create({
  baseURL: `${location.protocol}//moss${URL.ENV}com`,
  withCredentials: true,
})
