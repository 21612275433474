<template>
  <a-layout-header class="sad-header">
    <slot name="logo"></slot>
    <a-menu
      class="menu"
      theme="dark"
      mode="horizontal"
      v-if="isNeedIndex"
      :defaultSelectedKeys="[href]"
    >
      <a-menu-item key="https://moss.oa.com/">
        <a href="https://moss.oa.com/">营销云</a>
      </a-menu-item>
    </a-menu>
    <sad-nav></sad-nav>
    <slot></slot>
  </a-layout-header>
</template>

<script>
import { PLATFORM } from '../../../../../src/scripts/constants/index'
import SadNav from '../nav/index'

export default {
  name: 'sad-header',

  props: {
    isNeedIndex: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      PLATFORM,
      href: location.href,
    }
  },

  components: {
    SadNav,
  },
}
</script>

<style lang="postcss">
@import '../../../../../src/components/header/index.postcss';
</style>
