<script lang="jsx">
import { reqProduct } from '../../../../../src/scripts/request/index'

export default {
  name: 'sad-product',

  props: {
    defaultValue: {
      type: Number
    },

    number: {
      type: Number,
      default: 50,
    },

    showProduct: {
      type: Boolean,
      default: true
    },
    
    theme: {
      type: String,
    },
  },

  data() {
    return {
      product: {},
      products: [],
      busy: false,
    }
  },

  watch: {
    'defaultValue': function (nv, ov) {
      this.getProductDetail()
    }
  },

  methods: {
    onChange(value) {
      this.product = this.products.find(item => item.id == value)
      this.$emit('change', this.product)
      this.$emit('update:defaultValue', this.product.id)
    },

    onSearch(value) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getProductList(value)
      }, 500)
    },

    /**
     * 获取产品列表
     * @param {String} keyword - 搜索关键字
     */
    getProductList(keyword) {
      this.busy = true
      reqProduct.getList({
        owned: 0,
        from: 0,
        size: this.number,
        keyword,
      }).then(result => {
        const { products = [] } = result
        this.products = products
        this.busy = false
      }).catch(error => {
        this.busy = false
      })
    },

    getProductDetail() {
      reqProduct.detail(this.defaultValue).then(result => {
        this.product = result
        this.products = [result]
        this.getProductList()
      })
    }
  },

  mounted() {
    if (this.defaultValue) {
      this.getProductDetail()
    } else {
      this.getProductList()
    }
  },

  render() {
    return (
      <div class={`sad-product${this.theme == 'dark' ? ' dark' : ''} ${this.showProduct ? 'show-product' : ''}`}>
        {this.showProduct && (
          <div>
            <a-avatar size={64} shape='square' src={this.product.logo_url} />
            <div class='name'>{this.product.name}</div>
          </div>
        )}
        <a-select showSearch
          class='select'
          placeholder='搜索'
          filterOption={false}
          defaultActiveFirstOption={false}
          value={this.product.id}
          loading={this.busy}
          onChange={this.onChange}
          onSearch={this.onSearch}>
          {this.products.map(item => (
            <a-select-option key={item.id} value={item.id}>{item.name}（{item.identifiers.ams}）</a-select-option>
          ))}
        </a-select>
      </div>
    )
  }
}
</script>

<style lang="postcss">
@import '../../../../../src/components/product/index.postcss';
</style>
