<script lang="jsx">
import { URL } from '../../../../../src/scripts/constants/index'
import { reqCDN } from '../../../../../src/scripts/request/index'
// import nav from '../../../../../test/nav.json'

export default {
  name: 'sad-nav',

  data() {
    return {
      nav: [],
    }
  },

  created() {
    reqCDN.getNav().then(result => {
      this.nav = result
    })
    // this.nav = nav
  },

  render() {
    return (
      <a-popover class="sad-nav" placement="bottomLeft">
        <a-icon type="appstore" /> 营销解决方案
        <a-tabs class="sad-nav-tab" slot="content" tabPosition="left">
          {this.nav.map(item => (
            <a-tab-pane key={item.id} tab={item.name}>
              {item.children.map(item => (
                <div key={item.id}>
                  <div class="title">
                    <a-avatar
                      class="avatar"
                      size="small"
                      shape="square"
                      src={item.transform ? item.icon.replace(/\.(oa|qq)\./, URL.ENV) : item.icon}
                    />
                    <h3>{item.name}</h3>
                  </div>
                  {item.desc && <div class="desc">{item.desc}</div>}
                  <a-list
                    class="list"
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={item.children}
                    renderItem={item => (
                      <a-list-item>
                        <a disabled={!item.link}
                          target={item.target}
                          href={item.link ? (item.transform ? item.link.replace(/\.(oa|qq)\./, URL.ENV) : item.link) : ''}>{item.name} ></a>
                      </a-list-item>
                    )}></a-list>
                </div>
              ))}
            </a-tab-pane>
          ))}
        </a-tabs>
      </a-popover>
    )
  }
}
</script>

<style lang="postcss">
@import '../../../../../src/components/nav/index.postcss';
</style>
