/**
 * @file 接口 - 产品
 * @author hardylin <hardylin@tencent.com>
 */
import axios from './axios'

export default {
  /**
   * 详情
   * @param {Number} id - 产品 ID
   */
  detail(id) {
    return axios.get('/ingame/api/products/' + id).then(result => {
      if (result.data.code == 0) {
        return result.data.data
      } else {
        throw result.data
      }
    })
  },

  /**
   * 获取产品列表
   * @param {Object} params - 请求参数
   * @param {Number} params.owned - 是否为我的产品：1（是），0（否）
   * @param {Number} params.from - 从第几个开始
   * @param {String} params.size - 请求个数
   * @param {Number} params.category - 分类
   * @param {Number} params.kind - 子分类
   * @param {String} params.keyword - 关键字
   */
  getList(params) {
    return axios
      .get('/ingame/api/products', {
        params,
      })
      .then(result => {
        if (result.data.code == 0) {
          return result.data.data
        } else {
          throw result.data
        }
      })
  },
}
